<template>
  <div class="pt-6">
    <div class="md:w-1/2">      
      <h3 class="text-xl font-bold mb-6">Credit Card Transactions</h3>

      <datatable
        v-if="creditCardId"
        :url="
          `${this.$baseurl}/personal/card/transactions/${this.creditCardId}`
        "
        :ajax="true"
        :ajax-pagination="true"
        :columns="transactions.columns"
        :limit="5"
        :loading="personalCards.loading"
        :show-headings="false"
        :fillable="false"
        table-style="flush"
        ref="table"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Transactions',
  data() {
    return {
      transactions: this.$options.resource([], {
        url: ``,
        columns: [
          {
            name: "description",
            th: "Description",
            className: "w-full whitespace-normal",
            render: ({ category, transaction_type: transactionType, created_at: createdAt }, description) => `
                <div class="text-gray-700 font-medium text-sm">
                  ${this.$options.filters.sentenceCase(description) || transactionType}
                </div>
                <div class="text-gray-500 text-xs">
                  ${this.$options.filters.sentenceCase(category)} |
                  ${this.$options.filters.dateFormat(createdAt, "M dd")}
                </div>
              `,
          },
          {
            align: "right",
            name: "status",
            th: "Status",
            render: (transaction) => {
              var amount;

              if (transaction.amount > 0) {
                amount =
                  `- ₦` + this.$options.filters.currency(transaction.amount);
              } else {
                amount =
                  `+ ₦` +
                  this.$options.filters.currency(transaction.amount * -1);
              }

              return `
                  <div class="text-gray-700 font-medium text-sm">${amount}</div>
                  ${this.getTransactionStatus(transaction)}
                `;
            },
          },
        ],
      }),
    };
  },
  computed: {
    creditCard() {
      return this.personalCards.data?.[0];
    },
    creditCardId() {
      return this.creditCard?.id;
    },
    personalCards() {
      return this.resources.personalCards;
    },
  },
  methods: {
    getTransactionStatus({ status }) {
      let color;
      switch (status) {
        case 'failed':
          color = 'bg-red-500';
          break;
        case 'success':
          color = 'bg-green-500';
          break;
        case 'pending':
        default:
          color = 'bg-gray-500';
          break;
      }

      return `
        <div class="text-gray-500 text-xs flex items-center justify-end">
          <span class="w-5px h-5px rounded-50 ${color} inline-block mr-2"></span>
          <span>${this.$options.filters.sentenceCase(status)}</span>
        </div>
      `;
    },
  }
};
</script>
