<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">Transactions</h1>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<datatable :data="transactions.data" :columns="columns" :loading="transactions.loading" ref="table" />
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				searchQuery: '',
				company: null,
				loading: false,
				transactions: this.$options.resource([]),
				columns: [
					{
						name: 'description',
						th: 'Description',
					},
					{
						name: 'created_at',
						th: 'Date'
					},
					{
						name: 'amount',
						th: 'Amount'
					},
				]
			}
		},
		beforeMount() {
			this.getTransactions();
		},
		methods: {
			async getTransactions() {
				this.transactions.loading = true;

				await this.sendRequest('personal.transactions.all', this.user.id, {
					success: response => {
						console.log('response');
						console.log(response.data.data);
						this.transactions.data = response.data.data;
					},
					error: error => {
						this.transactions.error = error;
					}
				});

				this.transactions.loading = false;
			}
		}
	}
</script>