<template>
	<div class="-mt-6 border-t border-blue-200 -mx-6 px-6 md:pr-0 md:-mx-10 md:pl-6 xl:pl-10">
		
		<div class="flex flex-row flex-wrap border-b border-blue-200">
			<div class="w-full md:w-7/10 flex flex-col">

				<div class="special-section pt-6">
					<h1 class="text-2xl font-bold">Good {{ timeOfDay }}, {{ firstName }}</h1>
					<div class="text-gray-600 text-xs font-light mb-6 flex items-center">
						<span class="inline-block h-1 w-1 bg-blue-500 rounded-full mr-2"></span>
						<template>
							{{ position | sentenceCase }} at {{ company ? company.name : '' }}
						</template>
					</div>
				</div>

				<div class="grid grid-cols-1 md:grid-cols-3">
					<div class="col-span-1">
						<div class="px-8 xl:px-12 py-8 border-t border-b border-l border-blue-200 border-r md:border-r-0 relative">
							<span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
							<div class="flex justify-between items-center mb-4">
								<div class="text-xs">Total Employees</div>
							</div>
							<sm-loader v-if="loan_summary.loading" />
							<div class="text-xl" v-else>{{ totalEmployees }}</div>
						</div>
					</div>
					<div class="col-span-1">
						<div class="px-8 xl:px-12 py-8 border-t border-b border-l border-blue-200 border-r md:border-r-0 relative">
							<span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
							<div class="flex justify-between items-center mb-4">
								<div class="text-xs">Total Loans</div>
							</div>
							<sm-loader v-if="loan_summary.loading" />
							<div class="text-xl" v-else>₦ {{ loan_summary.total_loan_amount || 0 | currency }}</div>
						</div>
					</div>
					<div class="col-span-1">
						<div class="px-8 xl:px-12 py-8 border-t border-b border-l border-blue-200 border-r md:border-r-0 relative">
							<span class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
							<div class="flex justify-between items-center mb-4">
								<div class="text-xs">Total Due Loans</div>
							</div>
							<sm-loader v-if="loan_summary.loading" />
							<div class="text-xl" v-else>₦ 0.00</div>
						</div>
					</div>
				</div>
				<div class="border-l border-r border-blue-200 md:border-r-0 px-5 pt-10 flex-grow">
					<div>
						<div class="flex flex-row justify-between mb-4">
							<h2 class="text-xl font-bold">Recent Activities</h2>
							<router-link :to="{ name: 'activities' }" class="btn btn-sm btn-gray-soft" v-if="canAccess('activities')">View All</router-link>
						</div>

						<div>
									
							<datatable
								:data="activities.data"
								:columns="activities.columns"
								:loading="activities.loading"
								:fillable="false"
								ref="table"
							/>

						</div>

					</div>
				</div>

			</div>
			<div class="w-full md:w-3/10 border-l border-blue-200 border-r md:border-r-0 flex flex-col">

				<div class="px-10 py-10 border-b border-blue-200">

					<div class="mb-1">
						<div class="flex flex-row items-center mb-5">
							<span class="badge badge-xs badge-gray uppercase mr-2">Debit</span>
							<router-link :to="{ name: 'analytics' }" class="text-sm text-gray-600 flex flex-row items-center ml-auto" v-if="canAccess('analytics')">
								<span class="icon-bars mr-2">
									<span class="bar"></span>
									<span class="bar"></span>
									<span class="bar"></span>
								</span>
								<span>View Analytics</span>
							</router-link>
						</div>
					</div>
					<div class="text-sm">Wallet Balance</div>
					<template v-if="wallet.loading">
						<sm-loader />
					</template>
					<template v-else>
						<div class="flex flex-wrap items-center justify-between">
							<div class="text-lg font-medium mr-2 mb-2">₦ {{ debitBalance | currency }}</div>
							<button
								class="btn btn-sm btn-blue-outline mb-2"
								@click.prevent="openFundModal"
								v-if="canDo('company:can_fund_debit_wallet')"
							>
								<ion-icon name="add-outline" class="text-lg mr-1" />
								<span>Fund Wallet</span>
							</button>
						</div>
					</template>

				</div>

				<div class="px-10 py-10 border-b border-blue-200">

					<div class="mb-1">
						<div class="flex flex-row items-center mb-5">
							<span class="badge badge-xs badge-green uppercase mr-2">Credit</span>
							<router-link :to="{ name: 'analytics' }" class="text-sm text-gray-600 flex flex-row items-center ml-auto" v-if="canAccess('analytics')">
								<span class="icon-bars mr-2">
									<span class="bar"></span>
									<span class="bar"></span>
									<span class="bar"></span>
								</span>
								<span>View Analytics</span>
							</router-link>
						</div>
					</div>

					<div class="mb-10">
						<div class="text-sm">Credit Balance</div>
						<template v-if="wallet.loading">
							<sm-loader />
						</template>
						<template v-else>
							<div class="flex flex-wrap items-center justify-between">
								<div class="text-lg font-medium mr-2">
									₦ {{ creditLimit | currency }}<!--  / {{ 0.00 | currency }} -->
								</div>
							</div>
						</template>
					</div>


					<div>
						<div class="text-sm">Available Balance</div>
						<template v-if="wallet.loading">
							<sm-loader />
						</template>
						<template v-else>
							<div class="flex flex-wrap items-center justify-between">
								<div class="text-lg font-medium mr-2 mb-2">₦ {{ availableCredit | currency }}</div>
								<button
									class="btn btn-sm btn-blue-outline mb-2"
									@click.prevent="requestCredit"
									v-if="canDo('company:can_apply_for_credit')"
								>
									<ion-icon name="caret-up-circle-outline" class="text-lg mr-1" />
									<span>Request Credit</span>
								</button>
							</div>
						</template>
					</div>

				</div>

				<template v-if="cards.loading">
					<div class="px-10 py-10">
						<sm-loader />
					</div>
				</template>
				<template v-else-if="hasCards">
					<div class="px-10 py-10">

						<div class="mb-6">
							<credit-card
								:name="firstCard.user.name"
								exp-month="03"
								exp-year="22"
								type="corporate"
								:class="{ 'grayscale opacity-50': firstCard.status === 'inactive' }"
							/>
						</div>

						<div class="text-center" v-if="canDo('company:can_request_cards')">
							<router-link :to="{ name: 'cards-new' }" class="btn btn-sm btn-blue-outline">
								<ion-icon name="add-outline" class="text-xl mr-2"></ion-icon>
								<span>Create Card</span>
							</router-link>
						</div>

					</div>
				</template>

				<template v-else>
					<div class="px-10 py-10 border-b border-blue-200" v-if="canDo('company:can_request_cards')">
						<div class="bg-gray-100 border border-blue-200 rounded p-5 text-center">
							<img :src="cardPreview" alt="CredPal Cards" class="mb-5 w-full">

							<div class="text-sm font-light mb-8">Start issuing expense cards to employees instantly.</div>

							<button
								type="button"
								class="button bg-blue-500 text-white mb-5"
								:disabled="wallet.loading"
								@click.prevent="requestCard('debit')"
							>
								Create Card
							</button>
						</div>
					</div>

					<div class="px-10 pb-10 pt-16" v-if="canDo('company:can_request_cards')">
						<div class="text-center">
							<div class="mb-6">Need a <b>Credit Expense Card</b> instead?</div>

							<button
								type="button"
								class="button bg-blue-500 text-white mb-5"
								:disabled="wallet.loading"
								@click.prevent="requestCard('credit')"
							>
								Get Started
							</button>
							
						</div>
					</div>
				</template>

			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Welcome to your dashboard.
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">Take a look around and familiarize yourself with the system.</p>
					<p class="mb-2">Let's begin by <b class="text-black">creating a department</b>.</p>
				</div>
			</div>

			<router-link
				:to="{ name: 'groups-new' }"
				class="btn btn-blue-outline btn-md"
				@click="closeModal(modalKey, $refs.helpModal)"
			>
				Take Me There
			</router-link>

		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="businessLoanModal">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Need a Business Loan?
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">You will need to actively use the CredPal system for 3 months to qualify for a business loan.</p>
					<p>Or contact; <a href="tel:012296294" class="text-black font-normal">01-2296294</a> if urgent</p>
				</div>
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white"
				@click.prevent="$refs.businessLoanModal.close"
			>
				I Understand!
			</button>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="creditWalletEmptyModal">

			<img :src="assets.icons.lg.exclamation" class="mx-auto mb-8" height="90" alt="Error">

			<div class="font-light mb-10">
				You currently have no money in your credit wallet. Your wallet has to be funded before you can request a card.
			</div>

			<router-link :to="{ name: 'credit-application' }" class="btn btn-blue">
				Request Credit
			</router-link>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="debitWalletEmptyModal">

			<img :src="assets.icons.lg.exclamation" class="mx-auto mb-8" height="90" alt="Error">

			<div class="font-light mb-10">
				You currently have no money in your debit wallet. Fund wallet now to request a card.
			</div>

			<button type="button" class="btn btn-blue" @click.prevent="openFundModal">
				Fund Debit Wallet
			</button>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 pt-12" ref="fundModal">

			<h3 class="text-xl font-bold mb-10">Fund your wallet</h3>

			<form-group
				type="money"
				left-icon="cash-outline"
				name="amount"
				:form="fundForm"
				v-model="fundForm.data.amount.value"
			>
				Amount To Fund
			</form-group>

			<div class="text-center">
				<paystack
					:amount="fundForm.data.amount.value"
					class="btn btn-blue btn-md"
					:callback="fundWallet"
					:disabled="fundForm.loading"
				>
					<span v-if="fundForm.loading">Verifying...</span>
					<span v-else>Fund Wallet</span>
				</paystack>
			</div>

		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="fundSuccessModal">

			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-sm mb-10">
				Your wallet has been funded successfully.
			</div>

		</modal>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				fundForm: this.$options.basicForm([
					'amount', {name: 'payment_medium', value: 'paystack'}
				]),
				// wallet: this.$options.resource({}),
				// cards: this.$options.resource([]),
				// plan: this.$options.resource(null),
				cardPreview: require('@/assets/cards-preview.png'),
				loan_summary: {total_loans: 0, total_approved: 0, total_pending: 0, total_declined: 0, total_loan_amount: 0},
				modalKey: 'dashboard-company-help',
			}
		},
		computed: {
			activities() {
				return {
					...this.resources.activities,
					columns: [
						{
							name: 'description',
							th: 'Description',
							render: activity => this.getActivityDescription(activity)
						},
						{
							name: 'created_at',
							th: 'Date'
						},
						{
							name: 'amount',
							th: 'Amount',
							render: activity => {
								var amount;

								if (activity.type.match(/^staff_loan_/)) {
									amount = activity?.data?.loan?.loan_amount;
								}else if (activity.type.match(/^repayment_/)) {
									amount = activity?.data?.repayment?.amount;
								}else if (activity.type.match(/^settlement_/)) {
									amount = activity?.data?.settlement?.amount;
								}

								if (amount) {
									return `₦ ${this.$options.filters.currency(amount)}`;
								}

							}
						},
					]
				};
			},
			wallet() {
				return this.resources.wallet;
			},
			cards() {
				return this.resources.cards;
			},
			plan() {
				return this.resources.plan;
			},


			company() {
				return this.user?.company;
			},
			position() {
				return this.user.profile ? this.user.profile.position : 'HR';
			},
			totalEmployees() {
				const totalEmployees = this.company?.profile?.no_of_staff;

				return totalEmployees ? totalEmployees : 0;
			},
			debitBalance() {
				return this.wallet.data?.debit || 0;
			},
			availableCredit() {
				return this.wallet.data?.credit || 0;
			},
			creditLimit() {
				return this.wallet.data?.credit_limit || 0;
			},
			hasCards() {
				return this.cards.data.length;
			},
			firstCard() {
				return this.cards.data[0];
			},
		},
		beforeMount() {
			// this.getActivePlan();
			// this.getCompanyLoanStats();
			// this.getActivities();
			// this.getWallet();
			// this.getCards();
		},
		mounted() {
			this.openModal(this.modalKey, this.$refs.helpModal);
		},
		methods: {
			helpModalClosed() {
				this.closeModal(this.modalKey, this.$refs.helpModal);
			},
			requestCredit() {
				return this.$router.push({ name: 'credit-application', query: { cardType: 'credit' }  });
				// if (!this.plan.data) {
				// }
			},
			requestCard(type) {
				switch (type) {
					case 'credit':
						if (this.wallet.data.credit <= 0) {
							return this.$refs.creditWalletEmptyModal.open();
						}
						break;
					case 'debit':
						if (this.wallet.data.debit <= 0) {
							return this.$refs.debitWalletEmptyModal.open();
						}
						break;
				}

				// if (!this.plan.data) {
				// 	return this.$router.push({ name: 'subscription', query: { cardType: type } });
				// }

				return this.$router.push({ name: 'cards-new', query: { cardType: type } });
			},
			openFundModal() {
				this.$refs.debitWalletEmptyModal.close();
				this.$refs.creditWalletEmptyModal.close();
				this.$refs.fundModal.open();
			},


			async getCards() {
				this.loadResource('cards');
			},
			async getCompanyLoanStats() {
				this.loan_summary.loading = true;
				await this.$get({
					url: `${this.$baseurl}/companies/loans/summary/${this.user.company_id}`,
					headers: this.headers,
					success: (response) => {
						this.loan_summary = {
							...this.loan_summary,
							...response.data
						};
					},
					error: (error) => {
						console.log(error);
					}
				});
				this.loan_summary.loading = false;
			},
			async getActivities() {
				this.loadResource('activities');
			},
			async getWallet() {
				this.loadResource('wallet');
			},
			async getActivePlan() {
				this.loadResource('plan');
			},
			async fundWallet(data) {
				var { reference } = data;

				this.fundForm.loading = true;
				await this.$post({
					url: `${this.$baseurl}/companies/wallet/fund-debit-wallet`,
					data: {
						reference,
						company_id: this.user.company_id,
						debit: this.fundForm.data.amount.value,
						payment_medium: this.fundForm.data.payment_medium.value
					},
					headers: this.headers,
					success: () => {
						this.getWallet();
						this.fundForm.success = true;
						this.$refs.fundSuccessModal.open();
						this.$refs.fundModal.close();
					},
					error: error => {
						this.fundForm.error = error;
					}
				});
				this.fundForm.loading = false;
				this.fundForm.loaded = true;
			},
		}
	}
</script>

<style lang="sass" scoped>
	.special-section
		height: 100px
</style>