<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">Payment History</h1>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<datatable :data="activities.data" :columns="activities.columns" :loading="activities.loading" ref="table" />
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				activities: this.$options.resource([], {
					columns: [
						{
							name: 'description',
							th: 'Description',
							render: activity => this.getActivityDescription(activity)
						},
						{
							name: 'created_at',
							th: 'Date'
						},
						{
							name: 'amount',
							th: 'Amount',
							render: activity => {
								var amount;

								if (activity.type.match(/^staff_loan_/)) {
									amount = activity?.data?.loan?.loan_amount;
								}else if (activity.type.match(/^repayment_/)) {
									amount = activity?.data?.repayment?.amount;
								}else if (activity.type.match(/^settlement_/)) {
									amount = activity?.data?.settlement?.amount;
								}

								if (amount) {
									return `₦ ${this.$options.filters.currency(amount)}`;
								}

							}
						},
					]
				}),
			}
		},
		beforeMount() {
			this.getActivities();
		},
		methods: {
			async getActivities() {
				this.activities.loading = true;

				await this.$get({
					url: `${this.$baseurl}/companies/activities/${this.user.company_id}`,
					headers: this.headers,
					success: response => {
						this.activities.setValue(response.data.data);
						this.activities.loaded = true;
					},
					error: error => {
						this.activities.error = error;
					}
				});

				this.activities.loading = false;
			}
		}
	}
</script>