<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">Payment History</h1>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<datatable :data="activities" :columns="columns" :loading="loading" :query="searchQuery" ref="table" />
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				searchQuery: '',
				company: null,
				loading: false,
				activities: [],
				columns: [
					{
						name: 'description',
						th: 'Description',
					},
					{
						name: 'created_at',
						th: 'Date'
					},
					{
						name: 'amount',
						th: 'Amount'
					},
				]
			}
		},
		beforeMount() {
			this.getActivities();
		},
		methods: {
			async getActivities() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/activities/recent`,
					headers: this.headers,
					success: (response) => {
						this.activities = response.data.activities;
					}
				});
				this.loading = false;
			}
		}
	}
</script>